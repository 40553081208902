import {Link} from 'react-router-dom';
import './root.css';

export default function RootFallbackOutlet() {
    return (
        <>
            <span>TODO: Redirect to <Link to={"/apps"} >apps</Link></span>
        </>
    );
}
