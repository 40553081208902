import {getAppJobs} from "../../services/appService";
import {Link, useLoaderData, useNavigate, useParams, json} from "react-router-dom";
import StatusBulletListItem from "../../components/statusBulletListItem";
import './jobs.css'

export async function loader({params, request})
{
    const url = new URL(request.url);
    const offset = url.searchParams.get("offset") ?? 0;
    const limit = url.searchParams.get("limit") ?? 10;

    const jobs = await getAppJobs(params.appName, offset, limit);
    const data = {offset: offset, limit: limit, jobs: jobs }
    return json(data, {status: 200});
}

export default function Jobs() {
    let navigate = useNavigate();
    const {appName} = useParams();
    const {jobs, offset, limit} = useLoaderData();

    const handleNext = () => {
        if (jobs.length < limit) return; // This is also guarded by the UI.
        handleButtonClick(Number(offset) + Number(limit));
    }

    const handlePrev = () => {
        if (parseInt(offset) === 0) return; // This is also guarded by the UI.
        const newOffset = Math.max(Number(offset) - Number(limit), 0); // Offset cannot be negative.
        handleButtonClick(newOffset);
    }
    const handleButtonClick = (newOffset) => {
        navigate(`/apps/${appName}/jobs?offset=${newOffset}&limit=${limit}`);
    };

    const statusColorMap = {
        2: "orange", // Running
        3: "orange", // NeedsReview
        4: "green", // Accepted
        5: "red", // Rejected
        31: "red" // Error
    }

    return (
        <div id="jobsOutletContainer">
            <h1>Jobs</h1>
            <ul>
                {jobs.sort((job1, job2) => new Date(job2.createDate) - new Date(job1.createDate)).map(job => (
                    <StatusBulletListItem
                        key={job.jobId}
                        color={statusColorMap[job.status] || "grey"}
                    >
                        <div className="list-content">
                            <Link to={`/apps/${job.clientApp.clientAppId}/jobs/${job.jobId}`}>
                                <div className="job-refs">
                                    <div className="primary-job-ref">
                                        <strong>{job.primaryClientProvidedJobRef}</strong>
                                    </div>
                                    <div
                                        className="secondary-job-ref">{job.secondaryClientProvidedJobRef ?? ""}</div>
                                </div>
                            </Link>
                            <div className="job-dates">
                                <div className="create-date">
                                    Created: {(new Date(job.createDate)).toLocaleString()}
                                </div>
                                <div className="update-date">
                                    Updated: {(new Date(job.updateDate)).toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </StatusBulletListItem>
                ))}
            </ul>
            <div id="pageButtonsContainer">
                <button
                    id="prevButton"
                    onClick={handlePrev}
                    disabled={parseInt(offset) === 0}
                >
                    Prev
                </button>
                <button
                    id="nextButton"
                    onClick={handleNext}
                    disabled={jobs.length < limit}
                >
                    Next
                </button>
            </div>
        </div>
    );

}
