import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider, redirect} from "react-router-dom";
import Root from "./routes/root/root";
import ErrorPage from "./errorPage";
import Apps, {loader as appsLoader} from "./routes/apps/apps";
import Jobs, {loader as jobsLoader} from "./routes/jobs/jobs";
import Job, {loader as jobLoader} from "./routes/job/job";
import DiffEntry, {loader as diffEntryLoader} from "./routes/diffEntry/diffEntry";
import RootFallbackOutlet from "./routes/root/rootFallbackOutlet";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {
              path: "/",
              element: <RootFallbackOutlet />
            },
            {
                path: "apps",
                element: <Apps />,
                loader: appsLoader,

            },
            {
                path: "apps/:appName",
                loader: async ({params}) => {
                    return redirect(`/apps/${params.appName}/jobs`);
                },
            },
            {
                path: "apps/:appName/jobs",
                element: <Jobs/>,
                loader: jobsLoader,
            },
            {
                path: "apps/:appName/jobs/:jobId",
                element: <Job/>,
                loader: jobLoader,
                children: [
                    {
                        path: ":diffEntryId",
                        element: <DiffEntry/>,
                        loader: diffEntryLoader,
                    }
                ]

            }
        ]
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
