export default function StatusBulletListItem(params) {
    let bulletColor;

    switch(params.color) {
        case "orange":
            bulletColor = 'orange';
            break;
        case "green":
            bulletColor = 'green';
            break;
        case "red":
            bulletColor = 'red';
            break;
        default:
            bulletColor = 'grey';
    }

    const listItemStyle = {
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    };

    const bulletStyle = {
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50px',
        backgroundColor: bulletColor,
        marginLeft: '10px',
        marginRight: '10px',
    };

    return (
        <li style={listItemStyle}>
            <span style={bulletStyle} className={"status-bullet"} />
            {params.children}
        </li>
    );
}
