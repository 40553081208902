const baseUrl = process.env.REACT_APP_Q4J_VQA_SERVER_URL;

export const fetchFromServer = async (url, options) => {
    const headers = {
        "X-Api-Key": process.env.Q4J_VQA_API_KEY ?? process.env.REACT_APP_Q4J_VQA_API_KEY,
        ...options?.headers
    };

    return await fetch(`${baseUrl}/${url}`, {...options, headers});
}
