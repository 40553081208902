import { getApps } from "../../services/appService";
import { useLoaderData } from "react-router-dom";
import './apps.css';

export async function loader() {
    return await getApps();
}

export default function Apps() {
    const data = useLoaderData();
    return (
        <div>
            <h1>Apps</h1>
            <ul>
                {data.map(app => (
                    <li key={app.clientAppId}>
                        <a href={`/apps/${app.clientAppId}/jobs`}>{app.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}
