import {fetchFromServer} from "./serverClient";

export async function getApps() {
    return await fetchFromServer(`apps`).then(res => res.json());
}

export async function getAppJobs(appId, offset = 0, limit = 10) {
    const queryArgs = [];
    queryArgs.push(`offset=${offset}`);
    queryArgs.push(`limit=${limit}`);
    const queryString = `${queryArgs.join("&")}`;

    const url = `apps/${appId}/jobs?${queryString}`;

    return await fetchFromServer(url).then(res => res.json());

}
