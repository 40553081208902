import {getJob} from '../../services/jobService';
import {Outlet, useLoaderData} from "react-router-dom";
import DiffsSidebar from "./diffsSidebar";
import './job.css';
import {useState} from "react";

export async function loader({params}) {
    return await getJob(params.jobId);
}

export default function Job() {
    const initialJob = useLoaderData();
    const [job] = useState(initialJob);

    return (
        <div id="jobContainer">
            <DiffsSidebar job={job}/>
            <div id="jobOutletContainer">
                <Outlet context={job} />
            </div>
        </div>
    );
}
