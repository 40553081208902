import { fetchFromServer } from "./serverClient";

const blobUrlCache = {};
export async function getImageBlobUrl(entryId, imageName){
    const cacheKey = `${entryId}:${imageName}`;
    if (!blobUrlCache[cacheKey]) {
        const response = await fetchFromServer(`diffs/${entryId}/${imageName}`);
        if (response.ok) {
            const blob = await response.blob();
            blobUrlCache[cacheKey] = URL.createObjectURL(blob);
        }
    }

    return blobUrlCache[cacheKey];
}

export async function updateStatus(entryId, status){
    return await fetchFromServer(`diffs/${entryId}/status`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(status)
    }).then();
}
