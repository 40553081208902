import './root.css';
import {Link, useLocation} from "react-router-dom";

export default function Breadcrumb() {
    const location = useLocation();
    const segments = location.pathname.split('/').filter(segment => segment !== '');

    return segments.length > 0 && (
        <div className="breadcrumb">
            {segments.map((segmentName, index) => {
                    const capitalized = segmentName.charAt(0).toUpperCase() + segmentName.slice(1);

                    return (
                        <div key={index}>
                            <Link to={`/${segments.slice(0, index + 1).join('/')}`}>
                                {capitalized}
                            </Link>
                            {index < segments.length - 1 && <span className="separator"> > </span>}
                        </div>
                    )
                }
            )}
        </div>
    );
}
