import {Outlet} from 'react-router-dom';
import Breadcrumb from "./breadcrumb";
import './root.css';

export default function Root() {
    const url = window.location.href;
    return (
        <>
            <div id="headerContainer">
                <h1>VisualQA</h1>
                <Breadcrumb className="full-width" url={url}/>
            </div>
            <div id="mainContent" className="full-width">
                <Outlet/>
            </div>
        </>
    );
}
