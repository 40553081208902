import {getImageBlobUrl, updateStatus} from '../../services/diffEntryService';
import {Link, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";
import './diffEntry.css';
import {acceptanceKind} from "../../models/acceptanceKind";

export async function loader({params}) {
    let data = {entryId: params.diffEntryId};

    const promises = ["actual", "diff", "baseline"].map(imageName => getImageBlobUrl(data.entryId, imageName));

    const results = await Promise.all(promises);

    data["actual"] = results[0];
    data["diff"] = results[1];
    data["baseline"] = results[2];

    return data;
}

const statusDisplayText = {
    0: "Pending",
    1: "Accepted",
    2: "Rejected",
}

export default function DiffEntry() {
    const data = useLoaderData();
    const job = useOutletContext();
    const navigate = useNavigate();

    const diffs = job.jobData.diffs;
    let currentEntryIndex = diffs.findIndex(diff => diff.diffEntryId === data.entryId);

    const handleStatusUpdate = async (status) => {
        await updateStatus(data.entryId, status);
        diffs[currentEntryIndex].reviewStatus = status;

        if (status === acceptanceKind.Accept) {
            navigate(getNextDiffUrl());
        } else if (status === acceptanceKind.Reject) {
            navigate(getNextDiffUrl());
        } else {
            console.error("Invalid status: " + status);
        }
    }

    const getNextDiffUrl = () => {
        const nextEntryIndex = (currentEntryIndex + 1) % diffs.length;
        const nextEntryId = diffs[nextEntryIndex].diffEntryId;
        return _routeFor(nextEntryId);
    }

    const getPrevDiffUrl = () => {
        const nonNegativeSafeDecrement = diffs.length - 1; // -1 mod imageDirs.length
        const prevEntryIndex = (currentEntryIndex + nonNegativeSafeDecrement) % diffs.length;
        const prevEntryId = diffs[prevEntryIndex].diffEntryId;
        return _routeFor(prevEntryId);
    }

    const _routeFor = (entryId) => {
        return `/apps/${job.clientApp.clientAppId}/jobs/${job.jobId}/${entryId}`;
    }

    return (
        <>
            <div id="overlay">
                <div className="loader"/>
            </div>
            <div id="contentContainer">
                <div id="actionBar">
                    <Link id="prev" to={getPrevDiffUrl()}>Prev</Link>
                    <Link id="reject"
                          onClick={async () => await handleStatusUpdate(acceptanceKind.Reject)}>Reject</Link>
                    <Link id="accept"
                          onClick={async () => await handleStatusUpdate(acceptanceKind.Accept)}>Accept</Link>
                    <Link id="next" to={getNextDiffUrl()}>Next</Link>
                </div>
                <div id="imageContentHeader">
                    <div id="entryIndex">
                        {`Entry ${currentEntryIndex + 1} of ${diffs.length}`}
                    </div>
                    <div id="entryName">
                        {diffs[currentEntryIndex].entryName}
                    </div>
                    <div id="entryStatus">
                        {statusDisplayText[diffs[currentEntryIndex]["reviewStatus"]]}
                    </div>
                </div>
                <div id="imagesContainer">
                    <div className="image-container">
                        <div className="image-detail">Expected</div>
                        <img id="expected" src={data.baseline} alt="baseline"/>
                    </div>

                    <div className="image-container">
                        <div className="image-detail">Diff</div>
                        <img id="diff" src={data.diff} alt="diff"/>
                    </div>

                    <div className="image-container">
                        <div className="image-detail">Actual</div>
                        <img id="actual" src={data.actual} alt="actual"/>
                    </div>
                </div>
            </div>
        </>
    )
}
