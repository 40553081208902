import React from "react";
import {Link} from "react-router-dom";
import './job.css'
import StatusBulletListItem from "../../components/statusBulletListItem";

function SidebarContent(params) {
    const statusColorMap = {
        0: "orange", // Pending
        1: "green", // Accepted
        2: "red", // Rejected
    }
    return (
        <ul style={params.collapsed ? {display: "none" } : {display: "block"}}>
            {params.job.jobData.diffs.sort((x,y) => {
                const name1 = x.entryName.toUpperCase();
                const name2 = y.entryName.toUpperCase();
                if (name1 < name2) {
                    return -1;
                }
                if (name1 > name2) {
                    return 1;
                }
                return 0;
            }
            ).map((diff, index) => {
                return (
                    <StatusBulletListItem key={index} color={statusColorMap[diff.reviewStatus] || "grey"}>
                        <Link to={`${diff.diffEntryId}`}>{diff.entryName}</Link>
                    </StatusBulletListItem>
                )
            })}
        </ul>
    )
}

export default function DiffsSidebar(params) {
    const [collapsed, setCollapsed] = React.useState(false);
    const collapsedWidth = "20px"
    return (
        <div id="diffsSidebarContainer" style={collapsed ? {width: collapsedWidth} : {}}>
            <SidebarContent job={params.job} collapsed={collapsed}/>
            <div id="sidebarButtonContainer">
                <button id="sidebarCollapseButton"
                        onClick={() => setCollapsed(!collapsed)}>{collapsed ? ">" : "<"}</button>
            </div>
        </div>
    )
}
